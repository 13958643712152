<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Project List</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Show&nbsp;
                                        <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-right"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Search:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive mb-0">
                            <b-table
                                :busy="isBusy"
                                :items="units"
                                :fields="fields"
                                responsive="sm"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                sort-icon-left
                                :filter-included-fields="filterOn"
                                :per-page="perPage"
                            >
                                <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner
                                            class="align-middle"
                                        ></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                                <template v-slot:cell(status)="data">
                                    <p
                                        v-if="data.item.status == 0"
                                        style="color: red"
                                    >
                                        Available
                                    </p>
                                    <p
                                        v-if="data.item.status == 1"
                                        style="color: orange"
                                    >
                                        Unit Sold
                                    </p>
                                    <p
                                        v-if="data.item.status == 2"
                                        style="color: green"
                                    >
                                        Handed Over
                                    </p>
                                    <p
                                        v-if="data.item.status == 3"
                                        style="color: blue"
                                    >
                                        Unit Booked
                                    </p>

                                    <p
                                        v-if="data.item.status == 4"
                                        style="color: blue"
                                    >
                                        Not Handed Over
                                    </p>
                                    <p
                                        v-if="data.item.status == 5"
                                        style="color: black"
                                    >
                                        Show Villa
                                    </p>
                                    <p
                                        v-if="data.item.status == 6"
                                        style="color: black"
                                    >
                                        Project Manager
                                    </p>
                                    <p
                                        v-if="data.item.status == 7"
                                        style="color: black"
                                    >
                                        Office
                                    </p>

                                    <p
                                        v-if="data.item.status == 8"
                                        style="color: green"
                                    >
                                        Handed Over/ EIB
                                    </p>
                                    <p
                                        v-if="data.item.status == 9"
                                        style="color: green"
                                    >
                                        Handed Over/ Court Case
                                    </p>
                                    <p
                                        v-if="data.item.status == 10"
                                        style="color: green"
                                    >
                                        Handed Over/ SC Court Case
                                    </p>
                                    <p
                                        v-if="data.item.status == 11"
                                        style="color: blue"
                                    >
                                        Not Handed Over/ Court Case
                                    </p>

                                    <p
                                        v-if="data.item.status == 12"
                                        style="color: blue"
                                    >
                                        Not Handed Over/ Pending with EIB
                                    </p>
                                    <p
                                        v-if="data.item.status == 13"
                                        style="color: blue"
                                    >
                                        Not Handed Over/ Legal
                                    </p>
                                    <p
                                        v-if="data.item.status == 14"
                                        style="color: red"
                                    >
                                        Available/ GZ
                                    </p>
                                    <p
                                        v-if="data.item.status == 15"
                                        style="color: red"
                                    >
                                        FCW Owner
                                    </p>
                                </template>
                            </b-table>
                        </div>

                        <div class="row float-right">
                            <i
                                class="ri-arrow-left-line arrow"
                                @click="previous"
                            ></i>
                            &nbsp;&nbsp;&nbsp;
                            <i
                                class="ri-arrow-right-line arrow"
                                @click="next"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
import { BASE_URL } from "../../common";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            units: [],
            isBusy: true,
            totalRows: 1,
            currentPage: 1,
            perPage: 50,
            pageOptions: [5, 10, 25, 50],
            filter: null,
            filterOn: [],
            sortDesc: false,
            offset: 0,
            fields: [
                { key: "id", sortable: true },
                { key: "ref_no", sortable: true, label: "Unit Number" },
                { key: "name", sortable: true },
                { key: "type", sortable: true },
                { key: "price", sortable: true },
                { key: "plot", sortable: true, label: "Plot Area" },
                { key: "build_up", sortable: true },
                "status",
            ],
        };
    },

    mounted() {
        this.getDetails(this.$route.params.id);
    },
    methods: {
        getDetails(id) {
            console.log(id);
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}projects/${id}/?token=${localStorage.cs_user_token}&offset=${this.offset}&limit=50`
                    )
                    .then((res) => {
                        this.isBusy = false;
                        this.units = res.data.response;
                        console.log(res.data.response);
                    });
            }
        },

        previous() {
            if (this.offset == 0) {
                return;
            }
            this.offset = this.offset - 50;
            this.isBusy = true;
            this.getDetails(this.$route.params.id);
        },
        next() {
            this.isBusy = true;
            this.offset = this.offset + 50;
            this.getDetails(this.$route.params.id);
        },
    },

    computed: {
        projects() {
            return this.$store.getters["apidata/getProjects"];
        },
        rows() {
            return this.projectList.length;
        },
    },
};
</script>
<style scoped>
.arrow {
    font-size: 20px;
    font-weight: bolder;
}
.arrow:hover {
    text-align: center;
    cursor: pointer;
    background-color: #c59220;
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
</style>
